import React, { useState, useEffect } from 'react'
import { Button, Checkbox, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import CustomTextField from '../TextField/TextField'
import FeemonkText from '../../../../pay-later/components/atoms/FeemonkText'
import styles from './index.module.css'
import { BorderBottom } from '@mui/icons-material'
import { createApplication } from '../../../../pay-later/services/application1'
import { Box, Typography, } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import moment from 'moment'
import axios from 'axios'
import Error from '../../../Home/Error'
import { updateAddress } from '../../../../pay-later/services/application1'

export default function BasicDetails(props: any) {
  const {application} = props
  console.log(application, 'app in basic details')
  const {mobile, applicationId, userId, firstName, lastName, panId, dateOfBirth,
    currentAddress, currentCity, currentPincode, currentState
  } = application
  const [pan, setPan] = useState('')
  const [dob, setDob] = useState('')
  const [consent, setConsent] = useState(false)
  const [error, setError] = useState('')
  const [openedModal, setOpened] = useState<boolean>(false);
  const [link, setLink] = useState("");
  const [value, setValue] = useState('')
  const [openCard, setOpenCard] = useState(!panId ? 'pan': !application?.currentAddress ? 'address-details': '')
  const [address, setAddress] = useState({
    currentAddress:currentAddress ||   "",
    currentCity:  currentCity || "",
    currentState: currentState || "",
    currentPincode:  currentPincode ||"",
    permanentAddress: currentAddress ||  "",
    permanentCity: currentCity ||  "",
    permanentState: currentState || "",
    permanentPincode: currentPincode || "",
    userId: "",
    applicationId:  "",
  })
  const [newAuthToken, setNewAuthToken] = useState("");

  useEffect(() => {
    
    const getDetails = async () => {
      let authToken;
      try {
        const response1 = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/login/auth`, {
          method: "POST",
          body: JSON.stringify({
            memberId: userId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        const result1 = await response1.json();
        authToken = result1.data;
        setNewAuthToken(authToken)
      } catch (error) {
        console.log(error, 'login auth err')
      }
    };
    getDetails();
  }, []);

  const handleCloseConsent = () => setOpened(false);

  const handleRadioBtnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if((event.target as HTMLInputElement).value === 'no'){
      setAddress({...address, 
        permanentAddress: address.permanentAddress || '',
        permanentCity : address.permanentCity || '',
        permanentState: address.permanentState || '' ,
        permanentPincode: address.permanentPincode || '',
        currentAddress: '',
        currentCity: '',
        currentState: '',
        currentPincode: '',
      })
    }
    else{
      setAddress({
        ...address,
        permanentAddress: address.permanentAddress || '',
        permanentCity : address.permanentCity || '',
        permanentState: address.permanentState || '' ,
        permanentPincode: address.permanentPincode || '',
        currentAddress: address.permanentAddress || '',
        currentCity: address.permanentCity || '',
        currentState: address.permanentState || '',
        currentPincode: address.permanentPincode,
      })
    }
    setValue((event.target as HTMLInputElement).value);
  };

  const handleStartSession = (value: any) => {
    const randomGen =
      Date.now().toString(36) + Math.random().toString(36).substr(2);

    (window as any)
      .getBureauSession(
        process.env.REACT_APP_CONSENT_KEY,
        randomGen,
        value.firstName,
        "",
        value.lastName,
        value.mobile
      )
      .then((res: any) => {
        console.log(res);
        setOpened(true)
        setLink(res);
      });
  };

  const handleLoadSession = async () => {
    const result = await (window as any).startBureauSession();
    
    if (result) {
      switch (result.status) {  
        case "SUCCESS":
          try{
            const res = await axios.get( `${process.env.REACT_APP_DASHBOARD_URL}/rules/eligibility?phone=${mobile}`)
            const qecBody = {
              applicationId: applicationId,
              userId: userId,
              //instituteId: instituteId,
              studentName: firstName + " " + lastName  ,
              applicantName: firstName + " " +  lastName,
              panId: panId,
              dob: moment(dob).format("DD/MM/YYYY"),
              phone: mobile,
              status: "Created",
              eligibility: res?.data?.data?.status,
            };
            if(res?.data?.data?.status === 3 || res?.data?.data?.status === 1){
              await axios.post(
                  `${process.env.REACT_APP_DASHBOARD_URL}/rules/create/eligibility`,
                   qecBody
                )
                .then((result: any) => {
                  setOpenCard('address-details')
                })
                .catch((err) => {
                  setError(err)
                  console.log(err);
                });
            }
            else{
              setError('Not Eligible, Contact our team for assistance');
            }
          
            handleCloseConsent()
            setOpenCard('address-details')
        
          }catch(e){
            console.log(e, 'eligibility err')
          }
          
          break;

        case "EXIT":
          alert("Retry Submit");
          handleCloseConsent();
          break;

        case "ERROR":
          alert("Error Please Try Later");
          handleCloseConsent();
          break;

        default:
          alert("Contact our team for assistance");
          break;
      }
    }
  };


  const getPanPro = () => {
    // console.log("Clicked", userData);
    // TODO: Send longitude and latitude to the backend from here.

    // Latitude: {position.latitude}, Longitude: {position.longitude}

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        createApplication({
          mobile: mobile,
          panId: pan,
          dob:moment(dob, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          channelId: '2',
        }, '', 'apply-now')
          .then((result) => {
            if (result.message !== "Successful") {
              console.log("Error", result.message);
              setError(result.data || result.message);
              return;
            }
            if (result?.data === "Date of Birth not matching") {
              console.log("Error", result.data);
              setError(result.data);
              return;
            }

            const resAddress = result?.data
            setAddress({...address, permanentAddress: resAddress?.currentAddress, 
                            permanentCity: resAddress?.currentCity,
                            permanentState: resAddress?.currentState,
                            permanentPincode: resAddress?.currentPincode })
            handleStartSession(result.data)
           
          })
          .catch((err) => {
            console.log(err);
            setError("PAN not verified");
          });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
      alert("Please enable location services to proceed");
    }
  };

 
  const submitAddress = async () => {
    
    updateAddress(
      { userId,
        applicationId,
        currentAddress: address.currentAddress,
        currentCity: address.currentCity,
        currentState: address.currentState,
        currentPincode: address.currentPincode,
        permanentAddress:  address.permanentAddress ? address.permanentAddress  : address.currentAddress,
        permanentCity:address.permanentCity ? address.permanentCity : address.currentCity,
        permanentState: address.permanentState ? address.permanentState : address.currentState,
        permanentPincode:  address.permanentPincode ? address.permanentPincode : address.currentPincode,
        channelId: 5,
      },
      newAuthToken
    )
      .then((result) => {
        console.log(result);
        setOpenCard('')
        window.location.reload()
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
    <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openedModal}
          keepMounted
          closeAfterTransition
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              gap="40%"
              alignItems="center"
              style={{
                width: "371px",
                backgroundColor: "white",
                padding: "10px",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                OTP Consent
              </Typography>
              <IconButton
                style={{ border: "2px solid #DbDbDb", width: "40px" }}
                aria-label="remove"
                size="small"
                onClick={handleCloseConsent}
              >
                <ClearIcon />
              </IconButton>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <iframe
                width="370"
                height="630"
                src={link}
                onLoad={handleLoadSession}
                title="bureau"
              ></iframe>
            </Box>
          </Box>
        </Modal>
      </>
    {error && (<Error error={error} setError={setError}/>)}
    {(openCard==='pan') && <div style={{background:'#ffffff', padding:'30px', display:'flex', flexDirection:'column', alignItems:'center', flexWrap:'wrap', border:'1px solid #bfbfbf'}}>
      <CustomTextField label='PAN' value={pan} handleTextFieldOnChange={(pan : string) => setPan(pan.toUpperCase())}/>
      <CustomTextField label='Date of Birth' value={dob} type='date' handleTextFieldOnChange={(dob: string) => setDob(dob)}/>
      <div style={{ display:'flex'}}>
          <Checkbox  onChange={(e: any) => setConsent(e.target.checked)} style={{ marginRight: "5px", height: "25px", width: "25px",}} checked={consent} size='small'  />
          <p className={styles.consentText}>
            I consent and authorize <FeemonkText /> to get a background
            check and a consumer credit report on me.
          </p>
      </div>
      <Button disabled={!pan || !dob || !consent} color='error' variant="contained" sx={{textTransform:'none', fontFamily:'Outfit'}} onClick={getPanPro}>Verify</Button>
    </div>
   }
   {(openCard==='address-details') && 
   <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.card1}>
            <div className={styles.title}  >
              <p className={styles.titleText}>
                Permanent Address
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
                boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                borderRadius: "0px 0px 12px 12px",
              }}
            >
              <p>
                <span
                  style={{
                    color: "#D32028",
                    fontSize: "1.2em",
                    lineHeight:1
                  }}
                >
                  Note: 
                </span>
                  The following address details are obtained from your e-kyc,
                  please feel free to edit in case of changes
                </p>
              <div className={styles.addressInputGrid}>
                <CustomTextField label="Door No."  value={address.permanentAddress} handleTextFieldOnChange={(doNo: string) => setAddress({...address, permanentAddress: doNo})} />
                <CustomTextField  label="City" value={address.permanentCity} handleTextFieldOnChange={(city: string) => setAddress({...address, permanentCity: city})}/>
                <CustomTextField label="State"  value={address.permanentState} handleTextFieldOnChange={(state: string) => setAddress({...address, permanentState: state})}/>
                 <CustomTextField  type='number'  label="Pincode" value={address.permanentPincode} handleTextFieldOnChange={(pincode: string) => setAddress({...address, permanentPincode: pincode})}/>
              </div>
              <div className={styles.formContainer}>
                <p>Is this your Current Address?</p>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group" 
                  sx={{display:'flex', flexDirection:'row', mt:-3, }}
                  onChange={handleRadioBtnChange}
                >
                  <FormControlLabel sx={{width:'10%', 
                    '& .MuiFormControlLabel-label': {
                    fontFamily: 'Outfit',
                  },
                  }} value="yes" control={<Radio color="error" />} label="Yes" />
                  <FormControlLabel  sx={{width:'10%', ml:3,
                    '& .MuiFormControlLabel-label': {
                      fontFamily: 'Outfit',
                    },
                  }} value="no" control={<Radio color="error" />} label="No" />
                </RadioGroup>
              </div>
            </div>
            </div>
            <div className={styles.card1}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                // border: '1px solid #d32028',
                boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                borderRadius: "12px",
              }}
            >
            <div className={styles.title}>
                    <p className={styles.titleText}>
                      Current Address
                    </p>
                </div>
              <div className={styles.addressInputGrid2}>
                <CustomTextField  label="Door No."  value={address.currentAddress} handleTextFieldOnChange={(doNo: string) => setAddress({...address, currentAddress: doNo})}/>
                <CustomTextField label="City" value={address.currentCity}  handleTextFieldOnChange={(city: string) => setAddress({...address, currentCity: city})} />
                <CustomTextField label="State"  value={address.currentState} handleTextFieldOnChange={(state: string) => setAddress({...address, currentState: state})}/>
                <CustomTextField label="Pincode" type='number'  value={address.currentPincode} handleTextFieldOnChange={(pinCode: string) => setAddress({...address, currentPincode: pinCode})}/>
              </div>
            </div>
            </div> 
          </div>
          <Button  color='error' variant="contained" sx={{textTransform:'none', fontFamily:'Outfit'}}
             onClick={submitAddress}
              disabled={
                !address.currentAddress ||
                !address.currentCity ||
                !address.currentState ||
                !address.currentPincode ||
                (value === "yes" &&
                  (!address.permanentAddress ||
                    !address.permanentCity ||
                    !address.permanentState ||
                    !address.permanentPincode))
              }
          >Submit</Button>

      </div>
      }
    </>
  )
}
