import { styled } from '@mui/material/styles';


export const Homestyle = styled('div')(({ theme }) => ({

        '& .Appbar-main':{
            background:"transparent",
            boxShadow:"none",
            paddingTop: "23px",
            '& .logo':{
            width:"140px",
            height:"64px",
        },
  
        '& .MuiList-root':{
            display:"inline-flex",
            float:"right",
        },
        },
        '& .MuiButton-containedPrimary':{
            width:"183px",
            height:"35px",
            background:"#D32028",
            borderRadius:"60px",
            textTransform: "capitalize",
            position:"relative",
            fontFamily:"Outfit-Regular",
            fontSize:"1.2em",
            "&:hover":{
                background:"#D32028",
            },
        },
        '& .svgdwn':{
            position: "absolute",
             right: "12px",
             width:'2em',
             height:'2em'
        },
        '& .MuiButton-containedSecondary':{
            width:"90px",
            height:"35px",
            border:"1px solid #353535",
            color:"#353535",
            borderRadius:"60px",
            textTransform: "capitalize",
            fontFamily:"Outfit-Regular",
            fontSize:"1.2em",
            background:"transparent",
            boxShadow:"none",
            "&:hover":{
                background:"transparent",
            },

        },
        '& .paper-setting':{
            padding:"25px",
            borderRadius:"30px",
            marginTop: "10px",
            '& .MuiButton-textPrimary':{
                textTransform: "capitalize",
                color:"#353535",
                fontFamily:"Inter-Medium",
                fontSize:"1em",
                '& svg':{
                    paddingRight:"0px",
                    
                },
            },
            '& .paper-btn':{
                background:"#F9FAFB",
                padding:"4px",
            },
            '& .MuiButton-textSecondary':{
                textTransform: "capitalize",
                color:"#D32028",
                fontFamily:"Inter-Medium",
                background:"#FFFFFF",
                boxShadow:"0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
                borderRadius:"8px",
                fontSize:"1em",
                '& svg':{
                    paddingRight:"10px",
                },
            },
            '& .payment-text':{
                color:"#000000",
                fontFamily:"Outfit-Medium",
                fontSize:"1em",
                marginTop:"10px",
                marginBottom:"20px",
            },
            '& .started':{
                fontSize:"1.2em",
            },
            '& .login-btn':{
                fontFamily:"Outfit-Light",
                width:"10rem",
                height:"3.5rem",
                fontSize:"1.5em",
            },
        },
        '& .Priority':{
            color:"#FFFFFF",
            fontFamily:"Outfit-Light",
            fontSize:"1.5rem",
            paddingTop: "18px",
            paddingLeft:'10px'
        },
        '& .Crores':{
            color:"#FFFFFF",
            fontFamily:"Outfit-Light",
            fontSize:"1.5rem",
            paddingTop: "5px",
            paddingLeft:'10px'

           
        },
        '& .text-cror':{
            color:"#D32028",
        },
        '& .applybtn':{
            fontSize:"1.2em",
            marginLeft:'10px',
            marginTop: "5px",
            marginBottom: "51px",
        },
        '& .helpcenter':{
            color:"#D32028",
            fontFamily:"Inter-Regular",
            fontSize:"1em",
            paddingTop: "50px",
            textAlign:" center",
            cursor: "pointer",
            paddingBottom:"30px",
        },
        '& .listfooter':{
            display:"inline-flex",
            '& .MuiListItem-root':{
                color:"#101828",
                fontFamily:"Inter-Regular",
                fontSize:"1em",
            },
            '& svg':{
                marginRight:"10px",
            },
        },
        '& .Reserved':{
            color:"#101828",
            fontFamily:"Inter-Regular",
            fontSize:"1em",
            paddingTop: "30px",
            textAlign:" center",
            cursor: "pointer",
            paddingBottom:"30px",
        },
        '& .number-login':{
            color:"#101828",
            fontFamily:"Inter-Regular",
            fontSize:"1em",
            paddingBottom:"10px",
        },
        '& .Welcome':{
            color:"#101828",
            fontFamily:"Inter-Medium",
            fontSize:"30px",
            textAlign:"center",
            marginTop: "50px",
        },
        '& .details':{
            color:"#667085",
            fontFamily:"Inter-Regular",
            fontSize:"1.2em",
            textAlign:"center",
        },
        '& .Submit':{
            width:"100%",
        },
        '& .boxotp':{
            border: "1px solid #FFD9DA",

boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
borderRadius: "8px",
textAlign:"center",
        },
        '& .boxotp-text':{
            color:"#D32028",
            fontFamily:"Inter-Medium",
            fontSize:"2em",
        },
        '& .otpbtn':{
            height:"44px",
            width:"100%"
        },
        '& .click':{
            color:"#D32028",
            cursor:"pointer",
            fontFamily:"Inter-Medium",
        },
'& .optback':{
    textTransform: "capitalize",
    color:"#667085",
    fontFamily:"Inter-Medium",
    borderRadius:"8px",
    fontSize:"1em",
    '& svg':{
        paddingRight:"10px",
    },
},
//  md down 
[theme.breakpoints.down('md')]: {
   
    '& .Priority':{
        fontSize:"1.5em",

    },
    '& .Crores':{
 
        fontSize:"1em",

    },
},


//  sm down 
[theme.breakpoints.down('sm')]: {
   
    '& .logo-cntr':{
        textAlign:"center",
    },
    '& .listfooter':{
        display:"inline-block",
    },
    '& .boxotp-text':{
        fontSize:"1.5em",
    },
}

  }));