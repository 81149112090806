import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Grid, Box, Typography, Divider } from "@mui/material";
import { Mainpagestyle } from "./Style";
import { useLocation, useNavigate } from "react-router-dom";
import Educationcard from "../Home/Educationcard";
import Preveiouspayment from "./Preveiouspayment";
import Cap from "../../Assets/images/cap.svg";
import Calender from "../../Assets/images/calender.svg";
import document from "../../Assets/images/document.png";
import Insitutte from "../../Assets/images/Insitutte.svg";
import Pay from "../../Assets/images/Pay.svg";
import axiosInstance from "../../helpers/axios";
import eye from "../../Assets/images/eye1.png";
import Emipayment from "./Emipayment";
import export1 from "../../Assets/images/export1.png";
import { jwtDecode } from "jwt-decode";
import * as moment from "moment";

function Loan({closedLoans,loans}) {
 
  function formatNumberWithCommas(number) {
    return number?.toLocaleString("en-IN");
  }

  const handleLoanClick = (loanId, status, startDate, amount, dueDate,legacyLoanId) => {
    sessionStorage.setItem("LoanId", loanId);
    sessionStorage.setItem("LoanStatus", status);
    sessionStorage.setItem("LoanStardDate", startDate);
    sessionStorage.setItem("PrincipalAmount", amount);
    sessionStorage.setItem("DueDate", dueDate);
    sessionStorage.setItem("LegacyLoanId", legacyLoanId);

  };
  return (
    <>
      <Mainpagestyle>
        <Box my={5}>
          <Grid container>
            <Grid item xs={6} sm={4} md={3}>
              <Typography variant="body2" component="div" className="payment">
                Active Loans
                <svg
                  width="5"
                  height="23"
                  viewBox="0 0 5 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.461426"
                    y="0.772095"
                    width="4.17139"
                    height="21.7188"
                    rx="2.08569"
                    fill="#D32028"
                  />
                </svg>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} md={9}>
              <Box mt={2}>
                <Divider />
              </Box>
            </Grid>
          </Grid>
          {loans?.length > 0 ? (
            <>
              {loans?.map((item) => {
                return (
                  <div className="loan-payment-crd">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4} md={4} lg={2.5}>
                        <span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <svg
                              width="17"
                              height="21"
                              viewBox="0 0 17 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.93816 20.6557C6.33378 20.6557 0.914062 20.6557 0.914062 16.1394C0.914062 13.2782 3.48009 8.44524 6.09214 6.38692C6.34529 6.18712 6.71351 6.22859 6.91679 6.47739C7.12008 6.7262 7.07789 7.0881 6.82474 7.28791C4.27022 9.29722 2.08392 13.8361 2.08392 16.1394C2.08392 18.5597 4.00556 19.5059 8.93432 19.5059C13.8631 19.5059 15.7847 18.5634 15.7847 16.1394C15.7847 13.8361 13.5984 9.29722 11.0439 7.28414C10.7908 7.08434 10.7524 6.72243 10.9519 6.47362C11.1551 6.22482 11.5234 6.18712 11.7765 6.38315C14.3886 8.44524 16.9546 13.2744 16.9546 16.1357C16.9584 20.6557 11.5425 20.6557 8.93816 20.6557Z"
                                fill="black"
                              />
                              <path
                                d="M11.0989 7.48017H6.78003C6.03976 7.48017 5.44141 6.88831 5.44141 6.1645V5.83276C5.44141 5.10518 6.0436 4.51709 6.78003 4.51709H11.0989C11.8392 4.51709 12.4376 5.10895 12.4376 5.83276V6.1645C12.4376 6.88831 11.8354 7.48017 11.0989 7.48017ZM6.78003 5.66312C6.68798 5.66312 6.61127 5.73851 6.61127 5.82899V6.16073C6.61127 6.25121 6.68798 6.3266 6.78003 6.3266H11.0989C11.191 6.3266 11.2677 6.25121 11.2677 6.16073V5.82899C11.2677 5.73851 11.191 5.66312 11.0989 5.66312H6.78003Z"
                                fill="black"
                              />
                              <path
                                d="M10.703 5.66309H7.16654C6.90572 5.66309 6.67558 5.49345 6.6027 5.24841L5.44819 1.33533C5.39832 1.16192 5.43284 0.973432 5.54024 0.830179C5.65147 0.686926 5.82408 0.60022 6.00819 0.60022H11.8652C12.0493 0.60022 12.2219 0.686926 12.3331 0.830179C12.4443 0.973432 12.4789 1.16192 12.4252 1.33533L11.2668 5.24841C11.1939 5.49345 10.9638 5.66309 10.703 5.66309ZM7.60763 4.51329H10.2657L11.0827 1.75379H6.78681L7.60763 4.51329Z"
                                fill="black"
                              />
                              <path
                                d="M8.93905 9.87396C7.83823 9.87396 6.7796 10.3075 6.00097 11.0728C5.22235 11.838 4.78509 12.8785 4.78125 13.9604C5.01139 19.3815 12.8667 19.3777 13.0969 13.9604C13.0969 12.8785 12.6558 11.838 11.8771 11.0728C11.0947 10.3037 10.0399 9.87396 8.93905 9.87396ZM8.93905 17.3344C8.03001 17.3344 7.15549 16.9763 6.51111 16.3467C5.86673 15.7134 5.50618 14.8577 5.50618 13.9604C5.69413 9.4819 12.184 9.48567 12.3719 13.9604C12.3719 14.8539 12.0075 15.7134 11.3632 16.3467C10.7188 16.9763 9.84809 17.3307 8.93905 17.3344Z"
                                fill="black"
                              />
                              <path
                                d="M8.89018 14.1037C9.10114 13.9982 9.28908 13.8436 9.43484 13.6626C9.55374 13.493 9.63429 13.297 9.67264 13.0896H10.294C10.294 13.0896 10.4129 12.9351 10.6239 12.6222C10.6239 12.6222 10.6239 12.6109 10.6124 12.6109H9.66114C9.62278 12.4148 9.54223 12.2452 9.40799 12.0906H10.294C10.4129 11.9361 10.5318 11.7777 10.6239 11.6081H7.28689C7.27539 11.6081 7.16799 11.7627 6.95703 12.0756V12.0869H7.83155C8.00415 12.0869 8.16141 12.1246 8.32251 12.1773C8.54881 12.2414 8.73292 12.3998 8.85182 12.592H7.29073C7.29073 12.592 7.17183 12.7466 6.96087 13.0595C6.96087 13.0595 6.96087 13.0708 6.97237 13.0708H8.95922C8.95922 13.1499 8.94771 13.214 8.92086 13.2781C8.74826 13.7456 8.28415 13.9793 7.55539 13.9793C7.42114 13.9793 7.27922 13.9793 7.14498 13.9529V14.4204C7.14498 14.4317 7.79319 15.1743 9.10497 16.671H9.8184C9.8299 16.671 9.8299 16.671 9.8299 16.6597V16.5692L8.1921 14.624C8.11155 14.5448 8.03484 14.443 7.9658 14.3374C8.28415 14.3374 8.61401 14.2583 8.89018 14.1037Z"
                                fill="black"
                              />
                            </svg>
                            <Typography
                              variant="body2"
                              component="div"
                              className="Beneficiary"
                            >
                              Principal Availed
                            </Typography>
                          </div>
                          <Typography
                            variant="body2"
                            component="div"
                            className="Tuviksh"
                          >
                            ₹{formatNumberWithCommas(item.pricipalAmount)}
                          </Typography>
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img
                            style={{ width: "1.5em", height: "1.5em" }}
                            src={Pay}
                            className="cap-setting"
                          />
                          <Typography
                            variant="body2"
                            component="div"
                            className="Program"
                          >
                            EMI
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          component="div"
                          className="Class"
                        >
                          ₹ {formatNumberWithCommas(item?.emi)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img
                            style={{ width: "1.5em", height: "1.5em" }}
                            src={Calender}
                            className="cap-setting"
                          />
                          <Typography
                            variant="body2"
                            component="div"
                            className="Program"
                          >
                            Loan Start Date
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          component="div"
                          className="Class"
                        >
                          {item.startDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2.5}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",

                            cursor: "pointer",
                          }}
                        >
                          <img
                            style={{ width: "2em", height: "2em" }}
                            src={document}
                          />
                          <p
                            style={{
                              fontFamily: "Inter-Medium",
                              fontSize: "1em",
                              color: "#D32028",
                              textDecoration: "underline",
                              margin: 0,
                              padding: 0,
                              paddingLeft: "5px",
                            }}
                          >
                            Pre-closure offer
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={8} sm={4} md={4} lg={2}>
                        <Link
                          to="/mainpage/loandetails"
                          style={{ textDecoration: "none", color: "black" }}
                          onClick={() =>
                            handleLoanClick(
                              item.loanId,
                              item.status,
                              item.startDate,
                              item.pricipalAmount,
                              item?.dueDate,
                              item?.legacyLoanId
                            )
                          }
                        >
                          <div style={{ cursor: "pointer" }}>
                            <Emipayment />
                          </div>
                        </Link>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={1}>
                        <Link
                          to="/mainpage/loandetails"
                          style={{ textDecoration: "none", color: "black" }}
                          onClick={() =>
                            handleLoanClick(
                              item.loanId,
                              item.status,
                              item.startDate,
                              item.pricipalAmount,
                              item?.dueDate,
                              item?.legacyLoanId
                            )
                          }
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              marginTop: "5px",
                              marginLeft: "25%",
                            }}
                          >
                            <img
                              style={{ width: "1.2em", height: "1.2em" }}
                              src={export1}
                            />
                          </div>
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </>
          ) : (
            <div
              className="loan-payment-crd"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontFamily: "Inter-Medium",
                  fontSize: "1.5em",
                  color: "#D32028",
                  padding: "0.7em",
                }}
              >
                No active loans
              </span>
            </div>
          )}

          <br />
          <br />
          {/* <Grid container>
            <Grid item xs={6} sm={4} md={3}>
              <Typography variant="body2" component="div" className="payment">
                Closed Loans
                <svg
                  width="5"
                  height="23"
                  viewBox="0 0 5 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.461426"
                    y="0.772095"
                    width="4.17139"
                    height="21.7188"
                    rx="2.08569"
                    fill="#D32028"
                  />
                </svg>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} md={9}>
              <Box mt={2}>
                <Divider />
              </Box>
            </Grid>
          </Grid>
          {closedLoans?.length > 0 ? (
            <>
              {closedLoans?.map((item) => {
                return (
                  <div className="loan-payment-crd">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4} md={4} lg={2.5}>
                        <span>
                          <Link
                            to="/mainpage/loandetails"
                            style={{ textDecoration: "none", color: "black" }}
                            onClick={() =>
                              handleLoanClick(
                                item.loanId,
                                item.status,
                                item.startDate,
                                item.pricipalAmount,
                                item?.dueDate
                              )
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <svg
                                width="10"
                                height="15"
                                viewBox="0 0 17 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.93816 20.6557C6.33378 20.6557 0.914062 20.6557 0.914062 16.1394C0.914062 13.2782 3.48009 8.44524 6.09214 6.38692C6.34529 6.18712 6.71351 6.22859 6.91679 6.47739C7.12008 6.7262 7.07789 7.0881 6.82474 7.28791C4.27022 9.29722 2.08392 13.8361 2.08392 16.1394C2.08392 18.5597 4.00556 19.5059 8.93432 19.5059C13.8631 19.5059 15.7847 18.5634 15.7847 16.1394C15.7847 13.8361 13.5984 9.29722 11.0439 7.28414C10.7908 7.08434 10.7524 6.72243 10.9519 6.47362C11.1551 6.22482 11.5234 6.18712 11.7765 6.38315C14.3886 8.44524 16.9546 13.2744 16.9546 16.1357C16.9584 20.6557 11.5425 20.6557 8.93816 20.6557Z"
                                  fill="black"
                                />
                                <path
                                  d="M11.0989 7.48017H6.78003C6.03976 7.48017 5.44141 6.88831 5.44141 6.1645V5.83276C5.44141 5.10518 6.0436 4.51709 6.78003 4.51709H11.0989C11.8392 4.51709 12.4376 5.10895 12.4376 5.83276V6.1645C12.4376 6.88831 11.8354 7.48017 11.0989 7.48017ZM6.78003 5.66312C6.68798 5.66312 6.61127 5.73851 6.61127 5.82899V6.16073C6.61127 6.25121 6.68798 6.3266 6.78003 6.3266H11.0989C11.191 6.3266 11.2677 6.25121 11.2677 6.16073V5.82899C11.2677 5.73851 11.191 5.66312 11.0989 5.66312H6.78003Z"
                                  fill="black"
                                />
                                <path
                                  d="M10.703 5.66309H7.16654C6.90572 5.66309 6.67558 5.49345 6.6027 5.24841L5.44819 1.33533C5.39832 1.16192 5.43284 0.973432 5.54024 0.830179C5.65147 0.686926 5.82408 0.60022 6.00819 0.60022H11.8652C12.0493 0.60022 12.2219 0.686926 12.3331 0.830179C12.4443 0.973432 12.4789 1.16192 12.4252 1.33533L11.2668 5.24841C11.1939 5.49345 10.9638 5.66309 10.703 5.66309ZM7.60763 4.51329H10.2657L11.0827 1.75379H6.78681L7.60763 4.51329Z"
                                  fill="black"
                                />
                                <path
                                  d="M8.93905 9.87396C7.83823 9.87396 6.7796 10.3075 6.00097 11.0728C5.22235 11.838 4.78509 12.8785 4.78125 13.9604C5.01139 19.3815 12.8667 19.3777 13.0969 13.9604C13.0969 12.8785 12.6558 11.838 11.8771 11.0728C11.0947 10.3037 10.0399 9.87396 8.93905 9.87396ZM8.93905 17.3344C8.03001 17.3344 7.15549 16.9763 6.51111 16.3467C5.86673 15.7134 5.50618 14.8577 5.50618 13.9604C5.69413 9.4819 12.184 9.48567 12.3719 13.9604C12.3719 14.8539 12.0075 15.7134 11.3632 16.3467C10.7188 16.9763 9.84809 17.3307 8.93905 17.3344Z"
                                  fill="black"
                                />
                                <path
                                  d="M8.89018 14.1037C9.10114 13.9982 9.28908 13.8436 9.43484 13.6626C9.55374 13.493 9.63429 13.297 9.67264 13.0896H10.294C10.294 13.0896 10.4129 12.9351 10.6239 12.6222C10.6239 12.6222 10.6239 12.6109 10.6124 12.6109H9.66114C9.62278 12.4148 9.54223 12.2452 9.40799 12.0906H10.294C10.4129 11.9361 10.5318 11.7777 10.6239 11.6081H7.28689C7.27539 11.6081 7.16799 11.7627 6.95703 12.0756V12.0869H7.83155C8.00415 12.0869 8.16141 12.1246 8.32251 12.1773C8.54881 12.2414 8.73292 12.3998 8.85182 12.592H7.29073C7.29073 12.592 7.17183 12.7466 6.96087 13.0595C6.96087 13.0595 6.96087 13.0708 6.97237 13.0708H8.95922C8.95922 13.1499 8.94771 13.214 8.92086 13.2781C8.74826 13.7456 8.28415 13.9793 7.55539 13.9793C7.42114 13.9793 7.27922 13.9793 7.14498 13.9529V14.4204C7.14498 14.4317 7.79319 15.1743 9.10497 16.671H9.8184C9.8299 16.671 9.8299 16.671 9.8299 16.6597V16.5692L8.1921 14.624C8.11155 14.5448 8.03484 14.443 7.9658 14.3374C8.28415 14.3374 8.61401 14.2583 8.89018 14.1037Z"
                                  fill="black"
                                />
                              </svg>
                              <Typography
                                variant="body2"
                                component="div"
                                className="Beneficiary"
                              >
                                Principal Availed
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              component="div"
                              className="Tuviksh"
                            >
                              ₹{formatNumberWithCommas(item.pricipalAmount)}
                            </Typography>
                          </Link>
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img
                            style={{ width: "1.5em", height: "1.5em" }}
                            src={Pay}
                            className="cap-setting"
                          />
                          <Typography
                            variant="body2"
                            component="div"
                            className="Program"
                          >
                            Last EMI Date
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          component="div"
                          className="Class"
                        >
                          {item?.lastEmiDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2.5}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img
                            style={{ width: "1.5em", height: "1.5em" }}
                            src={Calender}
                            className="cap-setting"
                          />
                          <Typography
                            variant="body2"
                            component="div"
                            className="Program"
                          >
                            Loan Closure Date
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          component="div"
                          className="Class"
                        >
                          {item?.lastEmiDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2.5}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            style={{ width: "1.5em", height: "1.5em" }}
                            src={document}
                          />
                          <p
                            style={{
                              fontFamily: "Inter-Medium",
                              fontSize: "1em",
                              color: "#D32028",
                              textDecoration: "underline",
                              marginLeft: "5px",
                              whiteSpace: "nowrap",
                              margin: 0,
                              padding: 0,
                              paddingLeft: "5px",
                            }}
                          >
                            No-due Certificate
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={8} sm={4} md={4} lg={2}>
                        <Link
                          to="/mainpage/loandetails/emihistory"
                          style={{ textDecoration: "none" }}
                          onClick={() =>
                            handleLoanClick(
                              item.loanId,
                              item.status,
                              item.startDate,
                              item.pricipalAmount,
                              item?.dueDate
                            )
                          }
                        >
                          <div
                            style={{
                              boxShadow: " 0px 3px 3px 0px #0000002",
                              cursor: "pointer",
                              width: "auto",
                              height: "auto",

                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#D32028",
                              border: "none",
                              borderRadius: "30px",
                              padding: "5px",
                            }}
                          >
                            <img
                              style={{
                                marginLeft: "5px",
                                width: "auto",
                                height: "auto",
                              }}
                              src={eye}
                            />
                            <p
                              style={{
                                color: "white",
                                fontFamily: "Inter-Medium",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "5px",
                                fontSize: "1em",
                              }}
                            >
                              EMI History
                            </p>
                          </div>
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </>
          ) : (
            <div
              className="loan-payment-crd"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontFamily: "Inter-Medium",
                  fontSize: "1.5rem",
                  color: "#D32028",
                  padding: "0.7rem",
                }}
              >
                No closed loans
              </span>
            </div>
          )} */}
        </Box>
      </Mainpagestyle>
      <Educationcard />
      {/* <Preveiouspayment userData={rows} /> */}
    </>
  );
}

export default Loan;
