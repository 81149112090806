import React,{useRef} from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import download from '../../../Assets/images/download.png'
import Receipt from './Receipt';
import './receipt.css'







const App1 = ({receipt,type}) => {
  const company = {
  name: type==="repayment"?"MONK CAPITAL PVT LTD":"Feepay",
  address: `The Platina, B-406, Gachibowli, Hyderabad, Telangana, India, 500032`
};

  let user={}
  let receiptNo=''
  let date=''
  let fileName=''
  switch (type) {
    case "feepayment":   user = {
                          username: receipt.firstname, // Example username
                          LoanID: receipt.txnid, // Example LoanID
                          loanType:receipt.payment_name ,
                          amount:parseInt(receipt?.amountDue),
                          paymentMethod: receipt.mode,
                          bank:receipt.bankName?receipt?.bankName:"NA",
                          branch: receipt?.bankBranch?receipt?.bankBranch:"NA",
                          amountInWords: receipt.amount,
                          dateOfPayment: receipt.addedOn, // Example date of payment
                        };

                          receiptNo = receipt.txnid;
                          date = receipt.addedOn;
                          fileName = `Receipt_${receipt.firstname}_${date}.pdf`;
      
      break;
    case "repayment": user = {
                          username: `${(receipt?.firstname || "").trim()} ${(receipt?.lastname || "").trim()}`.trim(),
                          emiAmount:receipt?.emiAmount,
                          penalty:receipt?.paidOverdue,
                          bounceCharges:receipt?.paidBounceCharges?Math.round(receipt?.paidBounceCharges - (receipt?.paidBounceCharges - (receipt?.paidBounceCharges/1.18))):0,
                          bounceChargesGST:receipt?.paidBounceCharges?Math.round(receipt?.paidBounceCharges - (receipt?.paidBounceCharges/1.18)):0, // Example username
                          amount:receipt?.repaidEmiAmount,
                          amountInWords: receipt?.repaidEmiAmount,
                          emiNo:receipt?.emiNo,
                          dateOfPayment: receipt?.emiRepaidDate, // Example date of payment
                        };

                          receiptNo = receipt?.loanId + "_" + receipt?.emiNo;
                          date = receipt?.emiRepaidDate;
                          fileName = `Receipt_${receipt?.firstname}_${date}.pdf`;
      
      break;
   
  
    default: user = {};
      break;
  }
 
  // const handleCloseTab=()=>{
  //   document.getElementById('pdfpage').className="clicked"
  //   setTimeout(() => {
  //     window.close()
      
  //   }, 2000);
  // }
  
  return(
  <>
    <PDFDownloadLink document={<Receipt company={company} user={user} receiptNo={receiptNo} date={date} />} fileName={fileName}>
    {type==="feepayment"?<span>Receipt</span>:<></>}<img style={{width:'20px',height:'20px',marginLeft:'5px'}} src={download}/>
    </PDFDownloadLink>
  </>
  )

}




export default App1;
