// import { process.env.REACT_APP_PAYLATER_BACKEND } from "../utils/keys";

export const createApplication = async (data: any, token?: any, flow?:any) => {
    const path = flow === 'pay-later' ? 'create': 'create-from-pan'
    return fetch(`${process.env.REACT_APP_DASHBOARD_URL}/pay-later-flow/${path}`, {
      method: "POST",
      headers: {
        Accept: "application/JSON",
        "Content-Type": "application/JSON",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.error(error);
      });
  };
  
  export const updateAddress = async (data: any, token?: any) => {
    return fetch(`${process.env.REACT_APP_DASHBOARD_URL}/users/profile-details/create`, {
      method: "POST",
      headers: {
        Accept: "application/JSON",
        "Content-Type": "application/JSON",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.error(error);
      });
  };
  
  export const updateEmployment = async (data: any, token?: any) => {
    return fetch(`${process.env.REACT_APP_DASHBOARD_URL}/users/employment-details/create`, {
      method: "POST",
      headers: {
        Accept: "application/JSON",
        "Content-Type": "application/JSON",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.error(error);
      });
  };
  
  export const getApproval = async (data: any, token?: any) => {
    return fetch(
      `${process.env.REACT_APP_PAYLATER_BACKEND}/application/get-approval?userId=${data.userId}&applicationId=${data.applicationId}`
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.error(error);
      });
  };
  
  export const getProducts = async (data: any, token?: any) => {
    return fetch(
      `${process.env.REACT_APP_PAYLATER_BACKEND}/application/get-products?userId=${data.userId}&applicationId=${data.applicationId}`
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.error(error);
      });
  };
  
  export const approve = async (data: any, token?: any) => {
    return fetch(`${process.env.REACT_APP_PAYLATER_BACKEND}/application/approve`, {
      method: "POST",
      headers: {
        Accept: "application/JSON",
        "Content-Type": "application/JSON",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.error(error);
      });
  };
  