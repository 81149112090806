import React from 'react';
import { Document, Page, Text, View,Image } from '@react-pdf/renderer';
import NumberToWords from 'number-to-words'; // Library for converting numbers to words
import FeemonkLogo from '../../../Assets/images/FeeMonk_HighRes_Logo.png'

// Font family for the receipt
const font = {
  fontFamily: 'Helvetica',
};

const Receipt = ({ company, user, receiptNo }) => {
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`;
  const currentTime = new Date().toLocaleTimeString();
  // Convert amount to words
  const amountInWords = `${NumberToWords.toWords(user?.amount).toUpperCase()} RUPEES ONLY`;

  return (
    <Document>
      <Page size="A4">
        {/* Header */}
        <View style={{ padding: 20, ...font, fontSize: 10 }}>
          <View style={{ marginBottom: 10, flexDirection: 'column', alignItems: 'center' }}>
            <Image src={FeemonkLogo} style={{ width:80 }}/>
            <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', marginBottom: 5, textAlign: 'center' }}>{company.name}</Text>
            <Text style={{ fontSize: 10, textAlign: 'center' }}>{company.address}</Text>
            <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', textAlign: 'center', marginBottom: 10 ,marginTop: 2}}>Temporary Receipt</Text>
              <Text style={{ fontSize: 10, textAlign: 'center', marginTop: 8}}>Printed on: {formattedDate} {currentTime}</Text>
              {/* <Text style={{fontFamily: 'Helvetica-Bold'}}>Gachibowli</Text> */}
          </View>
          
          {/* Dots below the header */}
          <View style={{ marginTop: 10, marginBottom: 10, height: 1, backgroundColor: 'black' }} />

          {/* Body */}
          <View style={{ marginBottom: 10}}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10,borderBottom:'1px dashed #000'  }}>
              <Text style={{fontFamily: 'Helvetica-Bold'}}>Receipt No: {receiptNo}</Text>
              <Text>Date of Payment: {user.dateOfPayment}</Text>
            </View>
              <View style={{ marginBottom: 5,marginTop:15 ,display:'flex',flexDirection: 'row',justifyContent: 'space-between',paddingLeft:55,paddingRight:55 }}>
                <Text>EMI No</Text> <Text style={{fontFamily:'Helvetica-Bold'}}>{user.emiNo}</Text>
              </View>
              <View style={{ marginBottom: 5  ,display:'flex',flexDirection: 'row',justifyContent: 'space-between',paddingLeft:55,paddingRight:55 }}>
                <Text>Received with thanks from</Text> <Text> {user?.username}</Text>
              </View>
              {/* <View style={{ marginBottom: 5 }}>
                <Text>Towards: {user.loanType}</Text>
              </View> */}
              <View style={{ marginBottom: 5  ,display:'flex',flexDirection: 'row',justifyContent: 'space-between',paddingLeft:55,paddingRight:55 }}>
                <Text>EMI</Text> <Text>{user?.emiAmount?.toLocaleString('en-US', { minimumFractionDigits: 0 })}</Text>
              </View>
              <View style={{ marginBottom: 5  ,display:'flex',flexDirection: 'row',justifyContent: 'space-between',paddingLeft:55,paddingRight:55 }}>
                <Text>Penal Interest</Text> <Text>{user?.penalty?.toLocaleString('en-US', { minimumFractionDigits: 0 })}</Text>
              </View>
              <View style={{ marginBottom: 5  ,display:'flex',flexDirection: 'row',justifyContent: 'space-between',paddingLeft:55,paddingRight:55 }}>
                <Text>Bounce charges</Text> <Text>{user?.bounceCharges?.toLocaleString('en-US', { minimumFractionDigits: 0 })}</Text>
              </View>
              <View style={{ marginBottom: 5  ,display:'flex',flexDirection: 'row',justifyContent: 'space-between',paddingLeft:55,paddingRight:55 }}>
                <Text>GST</Text> <Text>{user?.bounceChargesGST?.toLocaleString('en-US', { minimumFractionDigits: 0 })}</Text>
              </View>
              <View style={{ marginBottom: 5  ,display:'flex',flexDirection: 'row',justifyContent: 'space-between',paddingLeft:55,paddingRight:55 }}>
                <Text>Total Amount</Text> <Text>{user?.amount?.toLocaleString('en-US', { minimumFractionDigits: 0 })}</Text>
              </View>
            
              {/*<View style={{ marginBottom: 5 }}>
                <Text>Bank: {user.bank}</Text>
              </View>
              <View style={{ marginBottom: 5 }}>
                <Text>Branch: {user.branch}</Text>
              </View> */}
              <View style={{ marginBottom: 5  ,display:'flex',flexDirection: 'row',justifyContent: 'space-between',paddingLeft:55,paddingRight:55}}>
                <Text>Amount in words</Text><Text style={{fontFamily:'Helvetica-Bold'}}>{amountInWords}</Text>
              </View>
            </View>

          {/* Body */}


          {/* Dots after the body */}
          <View style={{ marginTop: 10, marginBottom: 10, height: 1, backgroundColor: 'black' }} />

          {/* Footer */}
          <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'space-between', fontWeight: 'bold' }}>
            <Text style={{ fontFamily: 'Helvetica', marginLeft: 50 }}>***This is a system generated receipt***</Text>
         
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Receipt;
