import {useState, useEffect} from 'react'
import { RadioGroup, FormControlLabel, Radio, Box , Button} from '@mui/material'
import { updateEmployment } from '../../../../pay-later/services/application1'
import styles from './index.module.css'
import CustomTextField from '../TextField/TextField'
import Vector from "../../../../pay-later/images/static_assests/parent_img.svg"
import StudentIcons from "../../../../pay-later/images/static_assests/student_icon.svg";
import Label from '../../../../pay-later/components/atoms/Label'
import InputText from '../../../../pay-later/components/atoms/InputText'
import axiosInstance from '../../../../helpers/axios'
import BankStatementUpload from '../BankStatementUpload'
import CustomAutoComplete from '../AutoComplete/AutoComplete'
import axios from 'axios'
export default function WorkDetails(props:any) {
  const {application, stage} = props
  const {applicationId, userId } = application
  console.log(application, 'app in work details')
  const [employmentType, setEmploymentType] = useState<"salaried" | "self-employed" >("salaried");
  const [state, setState] = useState({ employerName: "", salary: "",incomePerMonth: "",typeOfBusiness: "" });
  const [newAuthToken, setNewAuthToken] = useState("");
  const [selectedCard, setSelectedCard] = useState(''); 
  const [studentName, setStudentName] = useState('')
  const [email, setEmail] = useState('')
  const [instituteName, setInstituteName] = useState('')
  const [courseName, setCourseName] = useState('')
  const [courseFees, setCourseFees] = useState('')
  const [instituteList, setInstituteList] = useState([])
  const [openCard, setOpenCard] = useState(!application?.employmentType ? 'work-details': 'identify-yourself')
  useEffect(() => {
  
    const getDetails = async () => {
      let authToken;
      try {
        const response1 = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/login/auth`, {
          method: "POST",
          body: JSON.stringify({
            memberId: userId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        const result1 = await response1.json();
        authToken = result1.data;
        setNewAuthToken(authToken)
      } catch (error) {
        console.log(error, 'login auth err')
      }
    };
    getDetails();
    getStudentDetails()
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.stage === 4) {
          setOpenCard('')
          console.log(res.data.stage, 'stage')
          return
         
        }
      })
      .catch((err) => console.log(err));
      
  }, []);

  const getEmail = async () => {
    try {
      const response: any = await axios(`${process.env.REACT_APP_DASHBOARD_URL}/summary/user?id=${userId}&applicationId=${applicationId}`)
      if(response?.status===200){
          const {email} = response?.data?.data
          setEmail(email)
          console.log(email, 'email')
      }
  } catch (err) {
      console.log(err)
    }
    
  }

  const getStudentDetails = async () =>{
    const response = await axios(`${process.env.REACT_APP_DASHBOARD_URL}/summary/user?id=${userId}&applicationId=${applicationId}`)
    if(response?.status===200){
      const {studentName, courseFees, instituteName, courseName} = response?.data?.data
      setStudentName(studentName)
      setCourseName(courseName)
      setInstituteName(instituteName)
      setCourseFees(courseFees)
      getEmail()
    }
  }
  const getInstituteInfo= async (searchVal: any)=>{
    setInstituteName(searchVal)
      try {
        if(searchVal?.length>=4){
            const response = await axiosInstance(`${process.env.REACT_APP_DASHBOARD_URL}/institute/info/name?name=${searchVal}`)
            const list = response?.data?.data
            setInstituteList(list)
          }
      } catch (error) {
        setInstituteList([])
        console.log(error, 'institute api err')
      }
 
  }
  
  const submitEmploymentDetails = () => {
    updateEmployment(
      { userId, applicationId, employmentType,
        employerName: state?.employerName,
        salary: Number(state?.salary),
        incomePerMonth: Number(state?.incomePerMonth),
        typeOfBusiness: state?.typeOfBusiness,
        channelId: 5,
      },
      newAuthToken
    )
      .then((result) => {
      
        setOpenCard('identify-yourself')
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCardClick = (cardType:any) => {
    setSelectedCard(cardType); 
  };
  
  const handleProcceed = async () => {
    setOpenCard('student-details')

 }

 const handleInstituteSelect = (name: string)=> {
  setInstituteName(name)
  setInstituteList([])
}

const validateEmail = (email: any) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email)

};

const updateEmail = async () => {
  const headers = {
    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcHBsaWNhdGlvbnMtYmFja2VuZCIsInVzZXJfaWQiOjUsImVtYWlsIjoic2FpcmFta0BmZWVtb25rLmNvbSIsImlhdCI6MTcxNjg3Mzc4NjY5Nywicm9sZSI6ImNybSIsImRpc3BsYXlfbmFtZSI6IlNhaSBSYW0gUmVkZHkiLCJtb2JpbGUiOiI2MzA5MTQ4MzMzIiwiZXhwIjoxNzE2ODc0MzkxNDk3fQ.lPHnxTija-VpfqMzFWfJJ5fkHDkWJYM-kt2ybO7AgA8`,
    "Content-Type": "application/json",
  };
  try {
     await  axios.post(`${process.env.REACT_APP_DASHBOARD_URL}/summary/update`, {email, applicationId, userId}, {headers})
  } catch (err) {
     console.log(err)
  }
}

const handleContinue = async () => {
  updateEmail()
  const data = {
   applicationId: applicationId ,
   courseFees:  courseFees,
   instituteName: instituteName,
   courseName: courseName,
   isCoapplicant: true,
   loanAmount: courseFees,
   studentName: studentName,
   channel:"2"
 }
 const authToken = localStorage.getItem("token") || "";

 try {
   const response = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/admin/application/update`, {
     method: "POST",
     headers: {
       Authorization: `Bearer ${newAuthToken}`,
       "Content-Type": "application/json",
     },
     body: JSON.stringify(data),
   });

   if (!response.ok) {
     console.log(response)
   }

   const result = await response.json();
   setOpenCard('bank-statement-upload')
   return true
 } catch (error) {
   console.log(error, 'error')
   return false;
 }

}
  return (
    <>
   {openCard==='work-details' &&
     <div className={styles.container}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="salaried"
        name="radio-buttons-group"
        row
        onChange={(e:any) => setEmploymentType(e.target.value)}
        className={styles.radioContainer}
        sx={{width: '400px',
          '@media (max-width: 768px)': {
            width: '250px',
          },
        }}
      >
        <FormControlLabel style={{width:'40%'}} value="salaried" control={<Radio />} label="Salaried"  sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Outfit', fontWeight:'bold' }}}/>
        <FormControlLabel style={{width:'40%'}}  value="self-employed" control={<Radio />} label="Self Employed"  sx={{  '& .MuiFormControlLabel-label': { fontFamily: 'Outfit', fontWeight:'bold', }}}/>
      </RadioGroup>
    {employmentType==='salaried' && <Box  sx={{ display:'flex' , flexDirection:"column", maxWidth:'300px'}} >
      <CustomTextField label={'Employer Name'} value={state.employerName}handleTextFieldOnChange={(eName: any) => setState({...state, employerName:eName})}/>
      <CustomTextField label={'Salary'} type='number' value={state.salary}   handleTextFieldOnChange={(salary: any) => setState({...state, salary:salary})}/>
    </Box>}
    {employmentType==='self-employed' && <Box       sx={{ display:'flex' , flexDirection:"column", maxWidth:'300px'}} >
      <CustomTextField label={'Type of Business'} value={state.typeOfBusiness} handleTextFieldOnChange={(business: any) => setState({...state, typeOfBusiness:business})}/>
      <CustomTextField label={'Income Per Month'} type='number' value={state.incomePerMonth}   handleTextFieldOnChange={(incomePerMonth: any) => setState({...state, incomePerMonth:incomePerMonth})}/>
    </Box>}
    <Button  color='error' variant="contained" sx={{textTransform:'none', fontFamily:'Outfit'}}  onClick={submitEmploymentDetails}
      disabled={  (employmentType === "salaried" &&(!state.employerName || !state.salary)) ||
         (employmentType==='self-employed' && (!state.incomePerMonth || !state.typeOfBusiness)) }
      >Next</Button>

    </div>
   }
   {openCard==='identify-yourself' && <div className={styles.container}>
   <div className={styles.main}>
          <h1 className={styles.headerText}>Identify Yourself!</h1>
          <div className={styles.card}  onClick={() => handleCardClick("parent")} style={{border:selectedCard==='parent'? '1px solid #d32028':'' , background: selectedCard==='parent'? 'linear-gradient(95.71deg, rgba(255, 246, 246, 0.9) -0.64%, rgba(255, 247, 241, 0.9) 104.18%)': ''}} >
            <img src={Vector} alt='parent'/>
            <p className={styles.description}>I am a <span style={{fontWeight:'bold', fontSize:'16px', fontFamily:'Outfit'}}>parent</span> & I am here to apply for my child's education loan</p>
          </div>
          <div className={styles.card}  onClick={() => handleCardClick("student")} style={{border:selectedCard==='student'? '1px solid #d32028':'' , background: selectedCard==='student'? 'linear-gradient(95.71deg, rgba(255, 246, 246, 0.9) -0.64%, rgba(255, 247, 241, 0.9) 104.18%)': '' }} >
            <img src={StudentIcons} alt='student'/>
            <p className={styles.description}>I am the <span style={{fontWeight:'bold', fontSize:'16px', fontFamily:'Outfit'}}>student</span> & I am looking for loan to support my education</p>
          </div>
          <br />
          <div className={styles.btnContainer}>
          <Button color='error' variant='contained' onClick={handleProcceed} disabled={!selectedCard}  sx={{textTransform:'none', fontFamily:'Outfit'}} >Proceed</Button>
          </div>
        
        </div>
   </div>
    }
   {openCard==='student-details' && 
    <div className={styles.container}>
      <div className={styles.main}>
        <h1 className={styles.headerText}>Student Details</h1>
      </div>
      <div className={styles.studentFieldsContainer}>
      <CustomTextField label='Student Name' value={studentName} handleTextFieldOnChange={(sName: any) => setStudentName(sName)} />
      <CustomTextField label='Email' value={email} handleTextFieldOnChange={(email: any) => setEmail(email)} />
      <CustomAutoComplete label={'Institute Name'} id='instituteName' value={instituteName} options={instituteList} handleAutoCompleteChange={(name: any) => getInstituteInfo(name)} /> 

      <CustomTextField label='Class / Course Name' value={courseName} handleTextFieldOnChange={(course: any) => setCourseName(course)} />
      <CustomTextField label='Course Fee' value={courseFees} type='number' handleTextFieldOnChange={(fees: any) => setCourseFees(fees)} />
      </div>
    
      <div style={{alignSelf:'center', marginTop:'10px'}}>
        <Button   color='error' variant='contained' sx={{textTransform:'none', fontFamily:'Outfit'}} onClick={handleContinue}
              disabled={!studentName || !instituteName || !courseName || !courseFees || !validateEmail(email)}
            
        >Continue</Button>
      </div>
  
  </div>
   }
   {openCard === 'bank-statement-upload' && 
    <BankStatementUpload applicationId={applicationId} userId={userId} setOpenCard={setOpenCard}/>
   }
  </>
  )
}
