import { useEffect, useState } from 'react'
import axiosInstance from '../../../../helpers/axios'
import './index.css'

export default function FinalMessage() {
    const [disbursementData, setDisbursementData] = useState({})
    const applicationId = sessionStorage.getItem('appId')
    const token = sessionStorage.getItem('token')
    const getDisbusementDetails = async ()=> {
        const url=`${process.env.REACT_APP_DASHBOARD_URL}/admin/application/application-profile?applicationId=${applicationId}`
        try {
            const response = await axiosInstance(url,{
                headers : {
                    Authorization: `Bearer ${token}`,
                    'Content-type': 'application/json',
                    
                  }
            })
            setDisbursementData(response?.data?.data?.profile?.disbursementData)
        } catch (error) {
            console.log(error, 'error')
        }
    }
    useEffect(() => {
        getDisbusementDetails()
    }, [])
   const formatedDate = new Date(disbursementData?.disbursedDate).toLocaleDateString('en-GB')
  return (
    <div className='fm-container'>
        <div>
            <span>
                <pc className='label'>UTR:</pc>
                <p className='value'>{disbursementData?.utr}</p>
            </span>
            <span>
                <p className='label'>Disbursed Date:</p>
                <p className='value'>{formatedDate}</p>
            </span>
            
        </div>
    </div>
  )
}
